<template>
  <div style="width: 100%;height: 100vh;background-color: rgb(8, 20, 32)">
<!--    <div id="bj">-->
<!--      <img :src="bjsrc" style="width: 100%;height: 100%" alt="">-->
<!--    </div>-->
    <div style="position: absolute;left: 25%;top: 2%">
      <img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/show_progess.png" style="width: 80%">
    </div>

    <!--    头部-->
    <div style="height: 100px;display: flex;justify-content: space-between">


      <!--      时间-->
      <div id="timestyle">
        {{ timenow }}
      </div>

    </div>
    <div style="display: flex;height: 10px;justify-content: space-between">
      <div style="display: flex;justify-content: space-between;width: 20%;height: 30px;line-height: 30px;font-size: 15px;padding-left: 10px;margin-left: 50px;margin-top: 5px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
        <span>隧道日进尺</span>
        <a href="https://www.cr12cz.cn/#/show_progess" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
      </div>
    </div>
    <div id="myEchartsSdgxNnmAlone" style="width: 100%;height: 85%;margin-top: 30px"></div>
  </div>
</template>

<script>
import {setNowTimesInHole} from "@/utils/time";

export default {
  name: "show_sdgxnum_alone",
  data(){
    return{
      time: '',
      bjsrc: 'https://czsc.oss-cn-beijing.aliyuncs.com/bj/show_progess_bj.png',
      timenow: '',
      urlqz: ''
    }
  },
  created() {
    const url = window.location.href;             //获取当前url
    const dz_url = url.split('#')[1];    //获取#/之前的字符串
    const cs = dz_url.split('?')[1];     //获取?之后的参数字符串
    this.time = cs.split("=")[1];
    let now_time = new Date(this.time).getTime();
    let gd_time = new Date('2022-08-31').getTime();

    if (now_time < gd_time){
      this.urlqz = 'https://www.gjegm.ltd/czsczq'
    }else {
      this.urlqz = 'https://www.gjegm.ltd/showdata'
    }
    setTimeout(this.getRateOfProgess, 100);
  },
  methods:{
    getRateOfProgess() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsSdgxNnmAlone'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post(this.urlqz + '/ShowProgess/getOperDayNumAll', (response) => {
        console.log(response)
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          let sumMile = 0;
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            sumMile = sumMile + response.obj2[i] * 1;
          }
          // 绘制图表
          myChart.setOption({
            color: ['rgb(250,200,88)', 'rgb(238,102,102)'],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            title: [
              {
                text: '单位: 米',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 15
                },
                top: 20,
                left: 150,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '70%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                color: '#fff',
                rotate: 30
              },
            },
            yAxis: [
              {
                show: true,
                type: 'value',
                splitLine: {
                  show: false
                },
                axisLine: {
                  show: true, //隐藏y轴
                }
              },
              {
                type: 'value',
                axisLabel: {
                  show: false, //隐藏刻度值
                }
              }
            ],
            series: [{
              name: '各洞口' + this.time + '日进度',
              type: 'line',
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: {
                      //数值样式
                      color: '#f1bd05',
                      fontSize: 12,
                    },
                  },
                }
              },
              data: response.obj2
            }]
          });
        }
      }, {
        time: this.time
      })
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.timenow = setNowTimesInHole(new Date())
    }, 1000)

  }
}
</script>

<style scoped>
#timestyle {
  color: #fff;
  margin-right: 30px;
  margin-top: 25px;
  height: 50px;
  line-height: 50px;
  padding: 0px 20px 0px 20px;
  background-color: rgb(12, 37, 48);
  font-size: 15px;
  border: 2px solid RGB(48, 84, 95);
  border-radius: 10px;
  margin-left: 50px;
}
</style>